<script setup lang="ts">
import { BuildingLibraryIcon as BuildingLibraryIconOutline } from '@heroicons/vue/24/outline'
import { BuildingLibraryIcon as BuildingLibraryIconSolid } from '@heroicons/vue/24/solid'
import { graphql } from '~/gql'
import { GetBreadcrumbsDocument } from '~/gql/graphql'

const route = useRoute()

const parts = (route.path as string).slice(5).split('/').filter(p => p.indexOf(':') > 0)
const divisionId = `ocd-division/${parts.join('/')}`

console.info(divisionId)

graphql(`
    query GetBreadcrumbs($input: GovBreadcrumbsInput!) {
    govBreadcrumbs(input: $input) {
        items {
        label
        labelHref
        jurisdictionHref
        jurisdictionSelected
        }
    }
    }
`)

const { data } = await useAsyncQuery(GetBreadcrumbsDocument, {
    input: {
        currentPath: route.path,
    },
})

const breadcrumbs = data.value?.govBreadcrumbs?.items ?? []

console.info(data.value)
</script>

<template>
    <nav class="flex border-b border-gray-200 bg-white" aria-label="Breadcrumb">
        <ol role="list" class="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
            <!-- <li class="flex">
                <div class="flex items-center">
                    <NuxtLink href="#" class="text-gray-400 hover:text-gray-500">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </NuxtLink>
                </div>
            </li> -->
            <li v-for="breadcrumb, index in breadcrumbs ?? []" :key="breadcrumb.label" class="flex">
                <div class="flex items-center group text-sm font-medium">
                    <svg
                        class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none"
                        fill="currentColor" aria-hidden="true"
                    >
                        <path v-if="index !== 0" d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <div class="flex flex-row relative">
                        <NuxtLink
                            class="ml-4 duration-300" :class="[
                                !breadcrumb.jurisdictionSelected && breadcrumb.jurisdictionHref ? 'group-hover:mr-10'
                                : breadcrumb.jurisdictionSelected ? 'mr-10' : 'mr-4']" :to="breadcrumb.labelHref"
                        >
                            {{ breadcrumb.label
                            }}
                        </NuxtLink>

                        <NuxtLink
                            v-if="breadcrumb.jurisdictionSelected" :href="breadcrumb.jurisdictionHref!"
                            class="absolute right-0"
                        >
                            <BuildingLibraryIconSolid class="ml-4 h-5 w-5 flex-shrink-0 mr-2 text-green-700" aria-hidden="true" />
                        </NuxtLink>

                        <div
                            v-if="!breadcrumb.jurisdictionSelected && breadcrumb.jurisdictionHref"
                            class="relative duration-300 -translate-x-4 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
                        >
                            <NuxtLink
                                v-if="breadcrumb.jurisdictionHref" :href="breadcrumb.jurisdictionHref"
                                class="absolute right-0"
                            >
                                <BuildingLibraryIconOutline class="ml-4 h-5 w-5 flex-shrink-0 mr-2 hover:text-green-700 text-gray-400" aria-hidden="true" />
                            </NuxtLink>
                        </div>
                    </div>
                    <!-- <div v-else class="ml-2"> </div> -->
                </div>
            </li>
        </ol>
    </nav>
</template>
